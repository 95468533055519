import { createContext, useContext, useEffect, useMemo } from "react";

import { usePianoEnv } from "@sciam/piano/react";
import { useFlags } from "./use-flags";

interface EnvironmentContextType {
  auth: {
    provider: "auth0";
    environment: string;
  };
  entitlements: {
    provider: "piano" | "chargebee";
    environment: string;
  };
  esp: {
    provider: "sendgrid" | "klaviyo";
    environment: string;
  };
}

const EnvironmentContext = createContext<EnvironmentContextType | undefined>(undefined);

export const ProvideEnvironment = ({ children }: { children: React.ReactNode }) => {
  // Flags passed from server, set via query param, or persisted in local storage
  const flags = useFlags();

  // Piano's environment is isolated to its own context
  const pianoEnv = usePianoEnv();
  const chargebeeFlag = flags?.chargebee || import.meta.env.PUBLIC_ENTITLEMENTS_PROVIDER === "chargebee";

  const environment: EnvironmentContextType = useMemo(
    () => ({
      auth: {
        provider: "auth0",
        // If the auth0 flag has an argument (e.g. flag=auth0:test), use that as the environment
        environment:
          (typeof flags?.auth0 === "string" && flags.auth0) || import.meta.env.PUBLIC_AUTH0_APP,
      },
      entitlements: chargebeeFlag
        ? {
            provider: "chargebee",
            environment:
              (typeof chargebeeFlag === "string" && chargebeeFlag) ||
              import.meta.env.PUBLIC_CHARGEBEE_APP,
          }
        : {
            provider: "piano",
            environment: pianoEnv?.slug,
          },
      esp: {
        provider: "sendgrid",
        environment: "prod",
      },
    }),
    // Prevent unnecessary re-renders on everything that uses the environment context
    [pianoEnv?.slug, pianoEnv?.authProvider, Object.values(flags || {})],
  );

  // Expose the environment to the window object for access outside of React
  useEffect(() => {
    window.__sa_env = environment;
  }, [environment]);

  return <EnvironmentContext.Provider value={environment}>{children}</EnvironmentContext.Provider>;
};

export const useEnvironment = (): EnvironmentContextType => {
  return useContext(EnvironmentContext);
};
